import React, { useState } from "react";
//import "./styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom"; 
import { Button } from 'react-bootstrap';

import Table from 'react-bootstrap/Table';

import "./Mobilestyles.css";

const data = [
  {
    ProjectName: "Oak Brook Gardens",
    LayoutArea: "38236 SQ.YDS",
    OpenSpace: "3823.60 SQ.YDS",
    RoadsArea: "12070.96 SQ.YDS",
    PlottedArea: "22341.44 SQ.YDS",
    NoPlots: "-",
    MortgageArea: "3581.60 SQ. YDS",
    Approvals: "C.No.3681/927/2022/E.P",
    SurveyNos: "-"

  }

];

export default function Alert() {
  const [show, setShow] = useState(true);
  const [selectedData, setSelectedData] = useState({});
  const handleClick = (selectedRec) => {
    setSelectedData(selectedRec);
    setShow(true);
  };

  const hideModal = () => {
    setShow(false);
  };

  return (
    <div className="App">
      {/* {show && <Modal details={selectedData} handleClose={hideModal} />} */}
      { show && <Modal handleClose={hideModal} /> }
    </div>
  );
}

const Modal = ({ handleClose, details }) => {
  return (
    <div className="modal display-block">
      <section className="mobile-modal-main">
        <div className="App">
        <Table striped hover responsive className="tableclass">
            <thead>
              <tr className="trclass">
                <th scope="col">Project Name</th>
                <th scope="col">Layout Area</th>
                <th scope="col">Open Space (10%)</th>
                <th scope="col">Roads Area (31.57%)</th>
                <th scope="col">Plotted Area (58.43%)</th>
                <th scope="col">No. Plots</th>
                <th scope="col">Mortgage Area (16.03%)</th>
                <th scope="col">Approvals</th>
                <th scope="col">Survey Nos</th>
              </tr>
            </thead>
            <tbody className="tbodyclass">
              <tr className="trclass">
                <td>{data[0].ProjectName}</td>
                <td>{data[0].LayoutArea}</td>
                <td>{data[0].OpenSpace}</td>
                <td>{data[0].RoadsArea}</td>
                <td>{data[0].PlottedArea}</td>
                <td>{data[0].NoPlots}</td>
                <td>{data[0].MortgageArea}</td>
                <td>{data[0].Approvals}</td>
                <td>{data[0].SurveyNos}</td>
              </tr>
            </tbody>
          </Table>
        </div>
        {/* <Button type="button" class="btn btn-primary btn-sm" onClick={handleClose}>close</Button> */}
 
        <Button className="button-block" variant="secondary" size="sm" onClick={handleClose}>close</Button>

  
      </section>
    </div>
  );
};
