/*global google*/
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import React, { useRef, useState, useEffect, Fragment } from "react";


import {slide as Menu} from "react-burger-menu";
import "./MobileSidebar.css";

// import { mail } from "react-bootstrap-icons";

import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarFooter,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
} from "cdbreact";
import { NavLink } from "react-router-dom";
import { isMobile, BrowserView, MobileView } from "react-device-detect";

import logo from "./logo.png"
import saralam from "./saralam.jpg"
import { HiOutlineMail } from 'react-icons/hi';
import { BiPhoneCall, BiMoney } from "react-icons/bi";
import { GrDirections } from "react-icons/gr";
import { FaRoad, FaEye } from "react-icons/fa";
import { TiInfoLarge } from "react-icons/ti";
import { CiMoneyCheck1 } from "react-icons/ci";

import { PiMoney } from "react-icons/pi";

// import { BiMoney } from 'react-icons/bi';

//import 'semantic-ui-css/semantic.min.css'

import Testmap from './Testmap';
import Testmaptwo from './Testmaptwo';
import { Button } from 'react-bootstrap';


import Mapone from "./Mapone";
import Maponetwo from "./Maponetwo";

import Maptwo from "./Maptwo";
import Maptwotwo from "./Maptwotwo";

import Alert from './Alert';
import Alerttwo from './Alerttwo';



import Mobilealert from './Mobilealert';
import Mobilealerttwo from './Mobilealerttwo';

import Purchaseoptions from './Purchaseoptions';
import Purchaseoptionstwo from './Purchaseoptionstwo';

import Mobilepurchaseoptions from "./Mobilepurchaseoptions";
import Mobilepurchaseoptionstwo from "./Mobilepurchaseoptionstwo";




function RefreshPage() {
  window.location.reload(false);
}



function SimpleMap() {

  const [mapd, setMapd] = useState(0);

  const [tabled, setTabled] = useState(0);

  const [purchased, setPurchased] = useState(0);




  let browser;

  if (isMobile) {
    browser = "mobile"
  } else {
    browser = "desktop"
  }

  // let mapd = 0;


 return (
  <>


  {/* MOBILE */}

  {browser=="mobile" ?
    
    <div className="App" id="outer-container">
      
     <Menu width={ '30%' }>    
     {/* <a href="https://www.saralam.tech"> <img src={saralam} alt="saralam" border="0" width={"40px"}/> </a> */}

      {/* Mobile info */}

      {tabled==0 && mapd==0 ? 
      (<TiInfoLarge width={"25px"} size={"30px"} style={{ color: "black" }} onClick={() => setTabled(tabled + 1)}/>)
      :
      tabled==0 && mapd==1 ? 
      (<TiInfoLarge width={"25px"} size={"30px"} style={{ color: "black" }} onClick={() => setTabled(tabled + 1)}/>)
      :
    tabled==0 && mapd==2 ?
      (<TiInfoLarge width={"25px"} size={"30px"} style={{ color: "black" }} onClick={() => setTabled(tabled + 2)}/>)
      :
    tabled==0 && mapd==3 ?
      (<TiInfoLarge width={"25px"} size={"30px"} style={{ color: "black" }} onClick={() => setTabled(tabled + 2)}/>)
      :
     tabled==1  ?
     (<TiInfoLarge width={"25px"} size={"30px"} style={{ color: "black" }} onClick={() => setTabled(tabled - 1)}/>)
      :
    tabled==2  ? 
    (<TiInfoLarge width={"25px"} size={"30px"} style={{ color: "black" }} onClick={() => setTabled(tabled - 2)}/>)  
     :
     (<TiInfoLarge width={"25px"} size={"30px"} style={{ color: "black" }} onClick={() => setTabled(tabled / 0)}/>)
}



      {/* Project Mobile*/}
     <br/><br/>
        {mapd==0 ?
        (<FaEye width={"25px"} size={"30px"} style={{ color: "black" }} />)
        : mapd==1 ?
        (<FaEye width={"25px"} size={"30px"} style={{ color: "black" }} />)
        : mapd==2 ?
        (<FaEye width={"25px"} size={"30px"} style={{ color: "black" }} />)
        : mapd==3 ?
        (<FaEye width={"25px"} size={"30px"} style={{ color: "black" }} />)
        : 
        (<FaEye width={"25px"} size={"30px"} style={{ color: "black" }} onClick={RefreshPage}/>)
        }



        {/* Purchase Options Mobile*/}
        <br/><br/>
        {purchased==0 && mapd==0 ? 
      (<PiMoney width={"25px"} size={"30px"} style={{ color: "black" }} onClick={() => setPurchased(purchased + 1)}/>)
      :
      purchased==0 && mapd==1 ? 
      (<PiMoney width={"25px"} size={"30px"} style={{ color: "black" }} onClick={() => setPurchased(purchased + 1)}/>)
      :
      purchased==0 && mapd==2 ?
      (<PiMoney width={"25px"} size={"30px"} style={{ color: "black" }} onClick={() => setPurchased(purchased + 2)}/>)
      :
    purchased==0 && mapd==3 ?
      (<PiMoney width={"25px"} size={"30px"} style={{ color: "black" }} onClick={() => setPurchased(purchased + 2)}/>)
      :
     purchased==1  ?
     (<PiMoney width={"25px"} size={"30px"} style={{ color: "black" }} onClick={() => setPurchased(purchased - 1)}/>)
      :
    purchased==2  ? 
    (<PiMoney width={"25px"} size={"30px"} style={{ color: "black" }} onClick={() => setPurchased(purchased - 2)}/>)  
     :
     (<PiMoney width={"25px"} size={"30px"} style={{ color: "black" }} onClick={() => setPurchased(purchased / 0)}/>)
}



        {/*Distance Mobile*/}
        <br/><br/>
        {mapd==1 ?
        (<FaRoad width={"25px"} size={"30px"} style={{ color: "black" }} onClick={Testmap}/>)
        : mapd==2 ?
        (<FaRoad width={"25px"} size={"30px"} style={{ color: "black" }} onClick={Testmaptwo}/>)
        :(<FaRoad width={"25px"} size={"30px"} style={{ color: "black" }} onClick={Testmap}/>)
        }
        <br/><br/>

        {/*Route Mobile*/}
        {mapd==0 ?
        (<GrDirections width={"25px"} size={"30px"} style={{ color: "black" }} onClick={() => setMapd(mapd + 1)}/>)
        : mapd==1 ?
        (<GrDirections width={"25px"} size={"30px"} style={{ color: "black" }} onClick={() => setMapd(mapd - 1)}/>)
        : mapd==2 ?
        (<GrDirections width={"25px"} size={"30px"} style={{ color: "black" }} onClick={() => setMapd(mapd + 1)}/>)
        : mapd==3 ?
        (<GrDirections width={"25px"} size={"30px"} style={{ color: "black" }} onClick={() => setMapd(mapd - 1)}/>)
        :
        <GrDirections width={"25px"} size={"30px"} style={{ color: "black" }} onClick={RefreshPage}/>
        }

        {/*Contact Info Mobile*/}
        <br/><br/>
     <a href="mailto:contact@saralam.tech"> <HiOutlineMail width={"25px"} size={"30px"} style={{ color: "black" }} /> </a>
        <br/><br/>
     <a href="tel:+914048597477"> <BiPhoneCall width={"25px"} size={"30px"} style={{ color: "black" }} /> </a>
     </Menu>

{/*Info Mobile*/}
<div style={{ display:'flex', alignItems:'center', justifyContent:'center'}}>
{tabled==1 ? (
    <Mobilealert></Mobilealert>
  ) 
  :
  tabled==2 ? (
    <Mobilealerttwo></Mobilealerttwo>
  )
  : null}
</div>

{/*Purchase Options Mobile*/}
<div style={{ display:'flex', alignItems:'center', justifyContent:'center'}}>
{purchased==1 ? (
    <Mobilepurchaseoptions></Mobilepurchaseoptions>
  ) 
  :
  purchased==2 ? (
    <Mobilepurchaseoptionstwo></Mobilepurchaseoptionstwo>
  )
  : null}
</div>


  {/*Map Mobile*/}
  <div style={{ height: "100vh", width:"100%"}}>
  {mapd==0 ? (
<Mapone></Mapone>
) : mapd==1 ? (
<Maponetwo></Maponetwo>
) :  mapd==2 ? (
  <Maptwo></Maptwo> 
) : mapd==3 ? (
  <Maptwotwo></Maptwotwo>

) : null

}
</div>
</div>
 : null} 
 


{/* DESKTOP */}
{browser=="desktop" ?
<Router>
  <div  style={{ display: 'flex', height: '100vh',  position: "inherit", overflow:"hidden", margin: "0 auto" }}>
    
<CDBSidebar  textColor="#333" backgroundColor="##f0f0f0" toggled="false" maxWidth='240px' > 

  <CDBSidebarHeader prefix={<i className="fa fa-bars fa-large"></i>}>
  <img src={logo} alt="Logo" width={"70px"} align="middle"/> 
    <a
      href="/"
      className="text-decoration-none"
      style={{ color: 'inherit' }}
    >
      Thirdeye
    </a>
  </CDBSidebarHeader>



  {browser=="desktop" ?
  <CDBSidebarContent className="sidebar-content">
    <CDBSidebarMenu>
    
{/* Info Desktop */}
{tabled==0 && mapd==0 ? (
      <NavLink activeClassName="activeClicked" onClick={() => setTabled(tabled + 1)}>
      <CDBSidebarMenuItem icon="info-circle"> Info</CDBSidebarMenuItem>
      </NavLink> )
      :
      tabled==0 && mapd==1 ? (
        <NavLink activeClassName="activeClicked" onClick={() => setTabled(tabled + 1)}>
        <CDBSidebarMenuItem icon="info-circle"> Info</CDBSidebarMenuItem>
        </NavLink> )
        :
    tabled==0 && mapd==2 ? (
        <NavLink activeClassName="activeClicked" onClick={() => setTabled(tabled + 2)}>
        <CDBSidebarMenuItem icon="info-circle"> Info</CDBSidebarMenuItem>
        </NavLink> )
        :
    tabled==0 && mapd==3 ? (
          <NavLink activeClassName="activeClicked" onClick={() => setTabled(tabled + 2)}>
          <CDBSidebarMenuItem icon="info-circle"> Info</CDBSidebarMenuItem>
          </NavLink> )
        :
     tabled==1  ? (
                <NavLink activeClassName="activeClicked" onClick={() => setTabled(tabled - 1)}>
                <CDBSidebarMenuItem icon="info-circle"> Info</CDBSidebarMenuItem>
                </NavLink> )
              
        :
    tabled==2  ? (
          <NavLink activeClassName="activeClicked" onClick={() => setTabled(tabled - 2)}>
          <CDBSidebarMenuItem icon="info-circle"> Info</CDBSidebarMenuItem>
          </NavLink> )
        
        :
      <NavLink activeClassName="activeClicked" onClick={() => setTabled(tabled / 0)}>
      <CDBSidebarMenuItem icon="info-circle"> Info</CDBSidebarMenuItem>
      </NavLink>
}

     

      {/* <Button onClick={() => setTable(table + 1)}> Details </Button> */}



{/* {Project} */}
{mapd==0 ? (
    <NavLink activeClassName="activeClicked" >
    <CDBSidebarMenuItem icon="eye" >Project </CDBSidebarMenuItem>
  </NavLink> )
    : mapd==1 ? (
      <NavLink activeClassName="activeClicked">
      <CDBSidebarMenuItem icon="eye" >Project Two</CDBSidebarMenuItem>
    </NavLink> )
  : mapd==2 ? (
    <NavLink activeClassName="activeClicked" >
    <CDBSidebarMenuItem icon="eye" >Project One</CDBSidebarMenuItem>
  </NavLink> )
    : mapd==3 ? (
      <NavLink activeClassName="activeClicked" >
      <CDBSidebarMenuItem icon="eye" >Project One</CDBSidebarMenuItem>
    </NavLink> )
  : 
  (<NavLink activeClassName="activeClicked" onClick={RefreshPage}>
  <CDBSidebarMenuItem icon="eye" >Project Two</CDBSidebarMenuItem>
</NavLink>) } 


{/* Purchase Options Desktop */}


{purchased==0 && mapd==0 ? (
      <NavLink activeClassName="activeClicked" onClick={() => setPurchased(purchased + 1)}>
      <CDBSidebarMenuItem icon="wallet"> Purchase Options </CDBSidebarMenuItem>
      </NavLink> )
      :
      purchased==0 && mapd==1 ? (
        <NavLink activeClassName="activeClicked" onClick={() => setPurchased(purchased + 1)}>
        <CDBSidebarMenuItem icon="wallet"> Purchase Options</CDBSidebarMenuItem>
        </NavLink> )
        :
    purchased==0 && mapd==2 ? (
        <NavLink activeClassName="activeClicked" onClick={() => setPurchased(purchased + 2)}>
        <CDBSidebarMenuItem icon="wallet"> Purchase Options </CDBSidebarMenuItem>
        </NavLink> )
        :
    purchased==0 && mapd==3 ? (
          <NavLink activeClassName="activeClicked" onClick={() => setPurchased(purchased + 2)}>
          <CDBSidebarMenuItem icon="wallet"> Purchase Options </CDBSidebarMenuItem>
          </NavLink> )
        :
     purchased==1  ? (
                <NavLink activeClassName="activeClicked" onClick={() => setPurchased(purchased - 1)}>
                <CDBSidebarMenuItem icon="wallet"> Purchase Options </CDBSidebarMenuItem>
                </NavLink> )
              
        :
    purchased==2  ? (
          <NavLink activeClassName="activeClicked" onClick={() => setPurchased(purchased - 2)}>
          <CDBSidebarMenuItem icon="wallet"> Purchase Options </CDBSidebarMenuItem>
          </NavLink> )
        
  :
      <NavLink activeClassName="activeClicked" onClick={() => setPurchased(purchased / 0)}>
      <CDBSidebarMenuItem icon="wallet"> Purchase Options </CDBSidebarMenuItem>
      </NavLink>
}




{/* {Distance Desktop} */}
{mapd==0 || mapd==1 ?
      (<NavLink activeClassName="activeClicked" onClick={Testmap}>
        <CDBSidebarMenuItem icon="road" >Direction</CDBSidebarMenuItem>
      </NavLink>) 
: mapd==2 ||mapd==3 ?
(<NavLink activeClassName="activeClicked" onClick={Testmaptwo}>
<CDBSidebarMenuItem icon="road" >Direction</CDBSidebarMenuItem>
</NavLink>)
:       
(<NavLink activeClassName="activeClicked" onClick={Testmap}>       {/*null causes error*/}
<CDBSidebarMenuItem icon="road" >Direction</CDBSidebarMenuItem>
</NavLink>)
}



{/* {Route Desktop} */}
{mapd==0 ?
    (<NavLink activeClassName="activeClicked" onClick={() => setMapd(mapd + 1)}>
    <CDBSidebarMenuItem icon="map" >Route</CDBSidebarMenuItem>
  </NavLink>)
    : mapd==1 ?
    (<NavLink activeClassName="activeClicked" onClick={() => setMapd(mapd - 1)}>
    <CDBSidebarMenuItem icon="map" >Route</CDBSidebarMenuItem>
  </NavLink>)
  : mapd==2 ?
  (<NavLink activeClassName="activeClicked" onClick={() => setMapd(mapd + 1)}>
  <CDBSidebarMenuItem icon="map" >Route</CDBSidebarMenuItem>
</NavLink>)
  : mapd==3 ?
  (<NavLink activeClassName="activeClicked" onClick={() => setMapd(mapd - 1)}>
  <CDBSidebarMenuItem icon="map" >Route</CDBSidebarMenuItem>
</NavLink>)
  :
null } 



      {/*Contact Desktop*/}

      <NavLink exact to="mailto:contact@saralam.tech" activeClassName="activeClicked">
        <CDBSidebarMenuItem icon="envelope">Email</CDBSidebarMenuItem>
      </NavLink>

      <NavLink exact to="tel:+914048597477" activeClassName="activeClicked">
        <CDBSidebarMenuItem icon="phone">Phone</CDBSidebarMenuItem>
      </NavLink>

     {/* {mapd==1 ?
           <NavLink exact to="/one" activeClassName="activeClicked" onClick={"RefreshPage"} >
           <CDBSidebarMenuItem icon="phone">Refresh</CDBSidebarMenuItem>
         </NavLink>
    :  <NavLink exact to="/two" activeClassName="activeClicked" >
    <CDBSidebarMenuItem icon="phone">Refresh</CDBSidebarMenuItem>
  </NavLink>}  */}

      
      
    </CDBSidebarMenu>
  </CDBSidebarContent>  : 
  <CDBSidebarContent className="sidebar-content">
  <CDBSidebarMenu>
  <img src={logo} alt="Logo" width={"80px"}/>
    <NavLink exact to="http://www.saralam.tech" activeClassName="activeClicked">
      <CDBSidebarMenuItem icon="home">Saralam</CDBSidebarMenuItem>
    </NavLink>
    {mapd==0 ? (
    <NavLink activeClassName="activeClicked" onClick={() => setMapd(mapd + 2)}>
    <CDBSidebarMenuItem icon="eye" >Project Two</CDBSidebarMenuItem>
  </NavLink> )
    : mapd==1 ? (
      <NavLink activeClassName="activeClicked" onClick={() => setMapd(mapd - 1)}>
      <CDBSidebarMenuItem icon="eye" >Project One</CDBSidebarMenuItem>
    </NavLink> )
  : mapd==2 ? (
    <NavLink activeClassName="activeClicked" onClick={() => setMapd(mapd + 0)}>
    <CDBSidebarMenuItem icon="eye" >Project Two</CDBSidebarMenuItem>
  </NavLink> )
  : 
  (<NavLink activeClassName="activeClicked" onClick={RefreshPage}>
  <CDBSidebarMenuItem icon="eye" >Project Two</CDBSidebarMenuItem>
</NavLink> ) } 
    <NavLink activeClassName="activeClicked" onClick={Testmap}>
      <CDBSidebarMenuItem icon="road">Direction</CDBSidebarMenuItem>
    </NavLink>
    {mapd==0 ?
    (<NavLink activeClassName="activeClicked" onClick={() => setMapd(mapd + 1)}>
    <CDBSidebarMenuItem icon="map" >Route</CDBSidebarMenuItem>
  </NavLink>)
  :
  (<NavLink activeClassName="activeClicked" onClick={RefreshPage}>
  <CDBSidebarMenuItem icon="map" >Route</CDBSidebarMenuItem>
</NavLink>) } 
    <NavLink exact to="mailto:contact@saralam.tech" activeClassName="activeClicked">
      <CDBSidebarMenuItem icon="envelope">Email</CDBSidebarMenuItem>
    </NavLink>
    <NavLink exact to="tel:+914048597477" activeClassName="activeClicked">
      <CDBSidebarMenuItem icon="phone">Phone</CDBSidebarMenuItem>
    </NavLink>
  </CDBSidebarMenu>
</CDBSidebarContent> }
  <CDBSidebarFooter style={{ textAlign: 'center' }}>
    <div
      className="sidebar-btn-wrapper"
      style={{
        padding: '20px 5px',
      }}
    >
      {/* Thirdeye Footer */}
    </div>
  </CDBSidebarFooter>
</CDBSidebar>

{/*Info Desktop*/}
<div style={{ height: "100vh", width:"100%"}}>

{
  tabled==1 ? (
    <Alert></Alert>
  ) 
  :
  tabled==2 ? (
    <Alerttwo></Alerttwo>
  )
  : null
}

{/*Purchase Options Desktop*/}
{
  purchased==1 ? (
    <Purchaseoptions></Purchaseoptions>
  ) 
  :
  purchased==2 ? (
    <Purchaseoptionstwo></Purchaseoptionstwo>
  )
  : null
}


{/*  Map Desktop */}
{mapd==0 ? (
<Mapone></Mapone>
) : mapd==1 ? (
<Maponetwo></Maponetwo>
) :  mapd==2 ? (
  <Maptwo></Maptwo> 
) : mapd==3 ? (
  <Maptwotwo></Maptwotwo>

) : null
}

</div>
</div>
</Router>

: null} 

  
</>


  
          
  );
}

export default SimpleMap;