/** @format */
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/functions";

const firebaseConfig = {
  apiKey: "AIzaSyAm_8KbHv1HEN6qy8_YhAgRHB6y75h7yyg",
  authDomain: "thirdeyeb2b-5465b.firebaseapp.com",
  projectId: "thirdeyeb2b-5465b",
  storageBucket: "thirdeyeb2b-5465b.appspot.com",
  messagingSenderId: "193651344896",
  appId: "1:193651344896:web:fa64d64574262b8114fae5",
  measurementId: "G-LRKTJRXB5C",
};
const app = firebase.initializeApp(firebaseConfig);

export default app;
