import React, { useState } from "react";
import "./styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom"; 
import { Button } from 'react-bootstrap';

import Table from 'react-bootstrap/Table';

const data = [
  {
    ProjectName: "Oak Brook Gardens",
    LayoutArea: "89+ acres",
    Year:"2000",
    NoPlots: "168",
    
    Approvals: "	GP",
    SurveyNos: "253-260",
    Address: "Mehar Nagar, Pochampally Mandal . Telangana 508284"

  }

];

export default function Alert() {
  const [show, setShow] = useState(true);
  const [selectedData, setSelectedData] = useState({});
  const handleClick = (selectedRec) => {
    setSelectedData(selectedRec);
    setShow(true);
  };

  const hideModal = () => {
    setShow(false);
  };

  return (
    <div className="App">
      {/* {show && <Modal details={selectedData} handleClose={hideModal} />} */}
      { show && <Modal handleClose={hideModal} /> }
    </div>
  );
}

const Modal = ({ handleClose, details }) => {
  return (
    <div className="modal display-block">
      <section className="modal-main">
        <div className="App">
          <Table  striped bordered hover responsive class="table">
            <thead>
              <tr>
                <th scope="col">Project Name</th>
                <th scope="col">Layout Area</th>
                <th scope="col">Year </th>
                
                <th scope="col">No. Plots</th>
                
                <th scope="col">Approvals</th>
                <th scope="col">Survey Nos</th>
                <th scope="col">Address</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{data[0].ProjectName}</td>
                <td>{data[0].LayoutArea}</td>
                <td>{data[0].Year}</td>
               
               
                <td>{data[0].NoPlots}</td>
               
                <td>{data[0].Approvals}</td>
                <td>{data[0].SurveyNos}</td>
                <td>{data[0].Address}</td>
              </tr>
            </tbody>
          </Table>
        </div>
        {/* <Button type="button" class="btn btn-primary btn-sm" onClick={handleClose}>close</Button> */}
 
        <Button  variant="outline-secondary" size="sm" onClick={handleClose}>close</Button>

  
      </section>
    </div>
  );
};
