/*global google*/


import React, { Component } from "react";
import GoogleMapReact from "google-map-react";
//import "./style.css"

//const google = window.google;



function GoogleMaps() {


      const apiIsLoaded = (map, maps) => {

        const directionsService = new google.maps.DirectionsService();
        const directionsRenderer = new google.maps.DirectionsRenderer();
        const destinationB = { lat:
          17.309318
          , lng:
          78.778487};
        directionsRenderer.setMap(map);
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
              (position) => {
                const currentPos = {
                  lat: position.coords.latitude,
                  lng: position.coords.longitude,
                };
        
                const request = {
                  origin: currentPos,
                  destination: destinationB,
                  travelMode: google.maps.TravelMode.DRIVING,
                  unitSystem: google.maps.UnitSystem.METRIC,
                  avoidHighways: false,
                  avoidTolls: false,
                };
                directionsService.route(request, function(response, status) {
                  if (status == google.maps.DirectionsStatus.OK) {
                    console.log(response);
                    directionsRenderer.setDirections(response);
            
                  }
                });
       
        
              },
              () => {
                
        
              }
            );
          }

          //FROM HERE

          
            const path = [
              { lat: 17.040349, lng: 78.568842 },
              { lat: 17.039901, lng: 78.569857 },
              { lat: 17.038629, lng: 78.570385 },
              { lat: 17.038499, lng: 78.570696 },
              { lat: 17.038341, lng: 78.570534 },
              { lat: 17.038108, lng: 78.570038 },
              { lat: 17.039087, lng: 78.568342 },
              { lat: 17.040349, lng: 78.568842 },
            ];
        
            
            const polygon = new maps.Polygon({
              paths: path,
              strokeColor: "#000000",
              strokeOpacity: 0.8,
              strokeWeight: 2,
              // fillColor: "#FF0000",
              fillOpacity: 0.35,
            });
        
        
        
            polygon.setMap(map);
        
            const infoWindow = new maps.InfoWindow(); 
        
        
 
const kurmidda = [

  { lat :17.307190, lng: 78.771130},
  {lat:17.308054, lng:78.772334},
  {lat:17.308766, lng:78.773680},
  {lat:17.308727, lng:78.773928},
  {lat:17.309070, lng: 78.774497},
  {lat:17.309234, lng:78.775022},
  {lat:17.309280, lng:78.775089},
  {lat:17.309782, lng:78.775689},
  {lat:17.311183, lng:78.778603},
  {lat:17.314451, lng:78.786080},
  {lat:17.306892, lng:78.781799},
  {lat:17.306736, lng:78.778354},
  {lat:17.307921, lng:78.775492},
  
  
  ];
  
  const entrance = [
  
  
  { lat:17.309830, lng: 78.772911 },
  
  { lat:17.309877, lng:78.772893},
  
  { lat:17.310422, lng:78.774288},
  
  { lat:17.308699, lng:78.775337},
  
  { lat:17.308694, lng:78.775267},
  
  { lat:17.310312, lng:78.774302},
  
  
  ];
  
  const plot1cs = [
    { lat:17.309070, lng: 78.774497},
    { lat:17.308563, lng: 78.774755}
  ];
  
  const plot2cs = [
    {lat:17.308727, lng:78.773928},
    {lat: 17.308445, lng: 78.774230}
  ];
  
  const plot3cs = [
    {lat:17.308499, lng:78.774770},
    {lat: 17.308108, lng: 78.774865}, 
  
    {lat: 17.308279, lng:78.774314 },
    {lat: 17.308388, lng:78.774243},
   
  ];
  
  const mainrd1cs = [
   {lat: 17.308445, lng: 78.774230},
   {lat: 17.308687, lng:78.775267},
   {lat: 17.308621, lng:78.775291},
   {lat: 17.308388, lng:78.774243},
  
  ];
  
  const mainrd1 = new google.maps.Polygon({
      paths: mainrd1cs,
      strokeColor: "BLACK",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#BLACK",
      fillOpacity: 0.5,
    });
  
  
  const mainrd2cs = [
   {lat: 17.308687, lng:78.775267},
   {lat:17.310256, lng:78.782758},
    {lat:17.310193, lng:78.782771},
  
  
   {lat: 17.308621, lng:78.775291},
   
  ];
  
  const mainrd2 = new google.maps.Polygon({
      paths: mainrd2cs,
      strokeColor: "BLACK",
      strokeOpacity: 0.8,
      strokeWeight: 1,
      fillColor: "#BLACK",
      fillOpacity: 0.5,
    });
  mainrd2.setMap(map);
  
  const main40ft = new google.maps.Marker({
      position: { lat: 17.309379, lng:78.778703},
      label: {text: "40ft", color: "red"},
      icon: {
        path: google.maps.SymbolPath.CIRCLE,
        scale: 10,
        strokeWeight: 1,
        fillColor: 'WHITE',
        strokeColor: 'WHITE',
        fillOpacity: 1
      },
      map: map,
     title: "40ft wide road",
      optimized: false,
    });
  
  
  const plot45cs = [
    {lat: 17.308621, lng:78.775291},
    {lat:17.307917, lng:78.775444},
    {lat: 17.308108, lng: 78.774865}, 
    
  ];
  
  const plot45 = new google.maps.Polyline({
      path: plot45cs,
       geodesic: true,
      strokeColor: "#FF0000",
      strokeOpacity: 1.0,
      strokeWeight: 2,
    });
  
  plot45.setMap(map);
  
  const plot4cs = [
    {lat:17.308263, lng: 78.774822},
    {lat:17.308353, lng:78.775353}
  ];
  
  const plot4 = new google.maps.Polyline({
      path: plot4cs,
       geodesic: true,
      strokeColor: "#FF0000",
      strokeOpacity: 1.0,
      strokeWeight: 2,
    });
  
  plot4.setMap(map);
  
  const mark4 = new google.maps.Marker({
      position: { lat: 17.308453, lng:78.775067},
      label: {text: "4", color: "white"},
       icon: {
        path: google.maps.SymbolPath.CIRCLE,
        scale: 8,
        strokeWeight: 2,
        fillColor: 'RED',
        strokeColor: 'RED',
        fillOpacity: 1
      },
      map: map,
       title: "50x90: 1000 SQYDS "
    });
  
    const mark5 = new google.maps.Marker({
      position: { lat:17.308149, lng:78.775178},
      label: {text: "5", color: "white"},
       icon: {
        path: google.maps.SymbolPath.CIRCLE,
        scale: 8,
        strokeWeight: 2,
        fillColor: 'RED',
        strokeColor: 'RED',
        fillOpacity: 1
      },
      map: map,
       title: "50x90: 1000 SQYDS "
    });
  
    const kurmiddalayout = new google.maps.Polygon({
      paths: kurmidda,
      strokeColor: "#FF0000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#FF0000",
      fillOpacity: 0,
    });
  
    const entrancerd = new google.maps.Polygon({
      paths: entrance,
      strokeColor: "BLACK",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#BLACK",
      fillOpacity: 0.5,
    });
  
  
    const plot1 = new google.maps.Polyline({
      path: plot1cs,
       geodesic: true,
      strokeColor: "#FF0000",
      strokeOpacity: 1.0,
      strokeWeight: 2,
    });
  
  
    const mark1 = new google.maps.Marker({
      position: { lat: 17.308918, lng:78.774877 },
      label: {text: "1", color: "white"},
       icon: {
        path: google.maps.SymbolPath.CIRCLE,
        scale: 8,
        strokeWeight: 2,
        fillColor: 'RED',
        strokeColor: 'RED',
        fillOpacity: 1
      },
      map: map,
       title: "50x90: 1000 SQYDS "
    });
  
     const plot2 = new google.maps.Polyline({
      path: plot2cs,
       geodesic: true,
      strokeColor: "#FF0000",
      strokeOpacity: 1.0,
      strokeWeight: 2,
    });
  
    const mark2 = new google.maps.Marker({
      position: { lat: 17.308716, lng:78.774398},
      label: {text: "2", color: "white"},
       icon: {
        path: google.maps.SymbolPath.CIRCLE,
        scale: 8,
        strokeWeight: 2,
        fillColor: 'RED',
        strokeColor: 'RED',
        fillOpacity: 1
      },
      map: map,
       title: "50x90: 600 SQYDS "
    });
  
  
    const plot3 = new google.maps.Polyline({
      path: plot3cs,
       geodesic: true,
      strokeColor: "#FF0000",
      strokeOpacity: 1.0,
      strokeWeight: 2,
    });
  
    const mark3 = new google.maps.Marker({
      position: { lat: 17.308289, lng:78.774697},
      label: {text: "3", color: "white"},
       icon: {
        path: google.maps.SymbolPath.CIRCLE,
        scale: 8,
        strokeWeight: 2,
        fillColor: 'RED',
        strokeColor: 'RED',
        fillOpacity: 1
      },
      map: map,
       title: "50x90: 600 SQYDS "
    });
  
    
  const rd9cs = [
    {lat: 17.311275, lng:78.780492 },
    {lat:17.309857, lng:78.780819},
    {lat:17.309882, lng:78.780921},
    {lat:17.311303, lng:78.780572},
  
  ];
  
  
  const rd9 = new google.maps.Polygon({
      paths: rd9cs,
      strokeColor: "BLACK",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#BLACK",
      fillOpacity: 0.5,
    });
  
  rd9.setMap(map);
  
  const rdmark9 = new google.maps.Marker({
      position: { lat: 17.309953, lng:78.780849},
      label: {text: "9th", color: "white"},
       icon: {
        path: google.maps.SymbolPath.CIRCLE,
        scale: 8,
        strokeWeight: 2,
        fillColor: 'RED',
        strokeColor: 'RED',
        fillOpacity: 1
      },
      map: map,
       title: "9th Road"
    });
  
  const rd940ft = new google.maps.Marker({
      position: { lat: 17.310496, lng: 78.780712},
    
      label: {text: "40ft", color: "red"},
      icon: {
        path: google.maps.SymbolPath.CIRCLE,
        scale: 10,
        strokeWeight: 1,
        fillColor: 'WHITE',
        strokeColor: 'WHITE',
        fillOpacity: 1
      },
      map: map,
     title: "40ft wide road",
      optimized: false,
    });
  
  const rd10cs = [
    {lat: 17.311410, lng: 78.781117 },
    {lat:17.309982, lng: 78.781438 },
    {lat:17.310000,  lng: 78.781510},
    {lat:17.311426,  lng: 78.781194},
  ];
  
  const rd10 = new google.maps.Polygon({
      paths: rd10cs,
      strokeColor: "BLACK",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#BLACK",
      fillOpacity: 0.5,
    });
  
  rd10.setMap(map);
  
  const rdmark10 = new google.maps.Marker({
      position: { lat: 17.310058, lng:78.781447}, 
      label: {text: "10th", color: "white"},
       icon: {
        path: google.maps.SymbolPath.CIRCLE,
        scale: 8,
        strokeWeight: 2,
        fillColor: 'RED',
        strokeColor: 'RED',
        fillOpacity: 1
      },
      map: map,
       title: "10th Road"
    });
  
  const rd1040ft = new google.maps.Marker({
      position: { lat: 17.310678, lng: 78.781321},
      
      label: {text: "40ft", color: "red"},
      icon: {
        path: google.maps.SymbolPath.CIRCLE,
        scale: 10,
        strokeWeight: 1,
        fillColor: 'WHITE',
        strokeColor: 'WHITE',
        fillOpacity: 1
      },
      map: map,
     title: "40ft wide road",
      optimized: false,
    });
  
  
  const plot118cs = [
    {lat: 17.310688, lng: 78.780728},
    {lat:17.310790, lng : 78.781259},
  ];
  
  
  const plot118 = new google.maps.Polyline({
      path: plot118cs,
       geodesic: true,
      strokeColor: "#FF0000",
      strokeOpacity: 1.0,
      strokeWeight: 2,
    });
  
  plot118.setMap(map);
  
  const plot119cs = [
    {lat: 17.310386, lng:  78.780806},
    {lat:17.310479, lng : 78.781316},
  ];
  
  
  const plot119 = new google.maps.Polyline({
      path: plot119cs,
       geodesic: true,
      strokeColor: "#FF0000",
      strokeOpacity: 1.0,
      strokeWeight: 2,
    });
  
  plot119.setMap(map);
  
    const mark118 = new google.maps.Marker({
      position: { lat: 17.310603, lng:78.780991},
  
      label: {text: "118", color: "white"},
       icon: {
        path: google.maps.SymbolPath.CIRCLE,
        scale: 8,
        strokeWeight: 2,
        fillColor: 'RED',
        strokeColor: 'RED',
        fillOpacity: 1
      },
      map: map,
       title: "100x180: 2000 SQYDS "
    });
  
  
    kurmiddalayout.setMap(map);
    //entrancerd.setMap(map);
    mainrd1.setMap(map);
    plot1.setMap(map);
    plot2.setMap(map);
    plot3.setMap(map);
  
  
        
        //FINE TILL HERE
        
     
        
        
        
        
      };


      
      return (
        <div>
          <div style={{ height: "100vh", width: "100%",  position: "inherit", overflow:"hidden", margin: "0 auto" }}>
            <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyB-IhLNuNEMTKd8VHG8Xyij2srPNVS_PR4",
                libraries: ["places", "geometry","visualization"],
              }}
              
              defaultCenter={{ lat:
                17.309318
                , lng:
                78.778487 }}
              defaultZoom={10}
            //   center={this.state.currentLocation}
              center={{ lat:
                17.309318
                , lng:
                78.778487 }}
              yesIWantToUseGoogleMapApiInternals
              //onGoogleApiLoaded={({ map, maps }) => renderPolygon(map, maps)} 
              onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps)}
              options={{
                styles: [ { featureType: "all", elementType: "labels", stylers: [ { visibility:  "on", } ] } ],
                mapTypeControl: false,
                mapTypeId: "hybrid",
                gestureHandling: "greedy",
                mapTypeControl: false,

                streetViewControl: false,
      
                zoomControl: false,
      
                fullscreenControl: true,
      
                rotateControl: true,
      
                disableDefaultUI: false,
      
                panControl: true,
      
                scaleControl: true,
      
                streetViewControl: true,
      
                overviewMapControl: true,
              }}
            />
          </div>
        </div>
      );
    //} //uncomment
  }

  export default GoogleMaps;