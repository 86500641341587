import React, { useRef, useState, useEffect, Fragment } from "react";
import GoogleMapReact from "google-map-react";
import firebase from "./firebase";
import { BrowserRouter as Router } from 'react-router-dom';

function Map() {

  const mapRef = useRef(null);
  const [mapData, setMapData] = useState(null);
  
  

  useEffect(() => {
    const fetchMapData = async () => {
      try {
        const proxyGoogleMaps = await firebase
          .functions()
          .httpsCallable("proxyGoogleMaps");

        const { data } = await proxyGoogleMaps({
          url: "http://maps.googleapis.com/maps/api/js?libraries=places&sensor=false&",
        });
        setMapData(data);
      } catch (error) {
        console.error("Error fetching map data:", error);
      }
    };
    fetchMapData();
  }, []);

  useEffect(() => {
    if (setMapData) {
      const script = document.createElement("script");

      script.type = "text/javascript";
      script.innerHTML = mapData;
      console.log("script ", script);
      document.head.appendChild(script);
      return () => {
        document.head.removeChild(script);
      };
    }
  }, [mapData]);

  const defaultProps = {
    center: { lat:
      17.309318
      , lng:
      78.778487
      },
    zoom: 18,
  };

  const renderPolygon = (map, maps) => {
    const path = [
      { lat:
        17.309318
        , lng:
        78.778487
        },
     
    ];

    
    const polygon = new maps.Polygon({
      paths: path,
      strokeColor: "#000000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      // fillColor: "#FF0000",
      fillOpacity: 0.35,
    });



    polygon.setMap(map);
    
    const infoWindow = new maps.InfoWindow(); 

    
const kurmidda = [

  { lat :17.307190, lng: 78.771130},
  {lat:17.308054, lng:78.772334},
  {lat:17.308766, lng:78.773680},
  {lat:17.308727, lng:78.773928},
  {lat:17.309070, lng: 78.774497},
  {lat:17.309234, lng:78.775022},
  {lat:17.309280, lng:78.775089},
  {lat:17.309782, lng:78.775689},
  {lat:17.311183, lng:78.778603},
  {lat:17.314451, lng:78.786080},
  {lat:17.306892, lng:78.781799},
  {lat:17.306736, lng:78.778354},
  {lat:17.307921, lng:78.775492},
  
  
  ];
  
  const entrance = [
  
  
  { lat:17.309830, lng: 78.772911 },
  
  { lat:17.309877, lng:78.772893},
  
  { lat:17.310422, lng:78.774288},
  
  { lat:17.308699, lng:78.775337},
  
  { lat:17.308694, lng:78.775267},
  
  { lat:17.310312, lng:78.774302},
  
  
  ];
  
  const plot1cs = [
    { lat:17.309070, lng: 78.774497},
    { lat:17.308563, lng: 78.774755}
  ];
  
  const plot2cs = [
    {lat:17.308727, lng:78.773928},
    {lat: 17.308445, lng: 78.774230}
  ];
  
  const plot3cs = [
    {lat:17.308499, lng:78.774770},
    {lat: 17.308108, lng: 78.774865}, 
  
    {lat: 17.308279, lng:78.774314 },
    {lat: 17.308388, lng:78.774243},
   
  ];
  
  const mainrd1cs = [
   {lat: 17.308445, lng: 78.774230},
   {lat: 17.308687, lng:78.775267},
   {lat: 17.308621, lng:78.775291},
   {lat: 17.308388, lng:78.774243},
  
  ];
  
  const mainrd1 = new maps.Polygon({
      paths: mainrd1cs,
      strokeColor: "BLACK",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#BLACK",
      fillOpacity: 0.5,
    });
  
  
  const mainrd2cs = [
   {lat: 17.308687, lng:78.775267},
   {lat:17.310256, lng:78.782758},
    {lat:17.310193, lng:78.782771},
  
  
   {lat: 17.308621, lng:78.775291},
   
  ];
  
  const mainrd2 = new  maps.Polygon({
      paths: mainrd2cs,
      strokeColor: "BLACK",
      strokeOpacity: 0.8,
      strokeWeight: 1,
      fillColor: "#BLACK",
      fillOpacity: 0.5,
    });
  mainrd2.setMap(map);
  
  const main40ft = new  maps.Marker({
      position: { lat: 17.309379, lng:78.778703},
      label: {text: "40ft", color: "red"},
      icon: {
        path:  maps.SymbolPath.CIRCLE,
        scale: 10,
        strokeWeight: 1,
        fillColor: 'WHITE',
        strokeColor: 'WHITE',
        fillOpacity: 1
      },
      map: map,
     title: "40ft wide road",
      optimized: false,
    });
  
  
  const plot45cs = [
    {lat: 17.308621, lng:78.775291},
    {lat:17.307917, lng:78.775444},
    {lat: 17.308108, lng: 78.774865}, 
    
  ];
  
  const plot45 = new  maps.Polyline({
      path: plot45cs,
       geodesic: true,
      strokeColor: "#FF0000",
      strokeOpacity: 1.0,
      strokeWeight: 2,
    });
  
  plot45.setMap(map);
  
  const plot4cs = [
    {lat:17.308263, lng: 78.774822},
    {lat:17.308353, lng:78.775353}
  ];
  
  const plot4 = new  maps.Polyline({
      path: plot4cs,
       geodesic: true,
      strokeColor: "#FF0000",
      strokeOpacity: 1.0,
      strokeWeight: 2,
    });
  
  plot4.setMap(map);
  
  const mark4 = new  maps.Marker({
      position: { lat: 17.308453, lng:78.775067},
      label: {text: "4", color: "white"},
       icon: {
        path:  maps.SymbolPath.CIRCLE,
        scale: 8,
        strokeWeight: 2,
        fillColor: 'RED',
        strokeColor: 'RED',
        fillOpacity: 1
      },
      map: map,
       title: "50x90: 1000 SQYDS "
    });
  
    const mark5 = new  maps.Marker({
      position: { lat:17.308149, lng:78.775178},
      label: {text: "5", color: "white"},
       icon: {
        path:  maps.SymbolPath.CIRCLE,
        scale: 8,
        strokeWeight: 2,
        fillColor: 'RED',
        strokeColor: 'RED',
        fillOpacity: 1
      },
      map: map,
       title: "50x90: 1000 SQYDS "
    });
  
    const kurmiddalayout = new  maps.Polygon({
      paths: kurmidda,
      strokeColor: "#FF0000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#FF0000",
      fillOpacity: 0,
    });
  
    const entrancerd = new  maps.Polygon({
      paths: entrance,
      strokeColor: "BLACK",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#BLACK",
      fillOpacity: 0.5,
    });
  
  
    const plot1 = new  maps.Polyline({
      path: plot1cs,
       geodesic: true,
      strokeColor: "#FF0000",
      strokeOpacity: 1.0,
      strokeWeight: 2,
    });
  
  
    const mark1 = new  maps.Marker({
      position: { lat: 17.308918, lng:78.774877 },
      label: {text: "1", color: "white"},
       icon: {
        path:  maps.SymbolPath.CIRCLE,
        scale: 8,
        strokeWeight: 2,
        fillColor: 'RED',
        strokeColor: 'RED',
        fillOpacity: 1
      },
      map: map,
       title: "50x90: 1000 SQYDS "
    });
  
     const plot2 = new  maps.Polyline({
      path: plot2cs,
       geodesic: true,
      strokeColor: "#FF0000",
      strokeOpacity: 1.0,
      strokeWeight: 2,
    });
  
    const mark2 = new  maps.Marker({
      position: { lat: 17.308716, lng:78.774398},
      label: {text: "2", color: "white"},
       icon: {
        path:  maps.SymbolPath.CIRCLE,
        scale: 8,
        strokeWeight: 2,
        fillColor: 'RED',
        strokeColor: 'RED',
        fillOpacity: 1
      },
      map: map,
       title: "50x90: 600 SQYDS "
    });
  
  
    const plot3 = new  maps.Polyline({
      path: plot3cs,
       geodesic: true,
      strokeColor: "#FF0000",
      strokeOpacity: 1.0,
      strokeWeight: 2,
    });
  
    const mark3 = new  maps.Marker({
      position: { lat: 17.308289, lng:78.774697},
      label: {text: "3", color: "white"},
       icon: {
        path:  maps.SymbolPath.CIRCLE,
        scale: 8,
        strokeWeight: 2,
        fillColor: 'RED',
        strokeColor: 'RED',
        fillOpacity: 1
      },
      map: map,
       title: "50x90: 600 SQYDS "
    });
  
    
  const rd9cs = [
    {lat: 17.311275, lng:78.780492 },
    {lat:17.309857, lng:78.780819},
    {lat:17.309882, lng:78.780921},
    {lat:17.311303, lng:78.780572},
  
  ];
  
  
  const rd9 = new  maps.Polygon({
      paths: rd9cs,
      strokeColor: "BLACK",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#BLACK",
      fillOpacity: 0.5,
    });
  
  rd9.setMap(map);
  
  const rdmark9 = new  maps.Marker({
      position: { lat: 17.309953, lng:78.780849},
      label: {text: "9th", color: "white"},
       icon: {
        path:  maps.SymbolPath.CIRCLE,
        scale: 8,
        strokeWeight: 2,
        fillColor: 'RED',
        strokeColor: 'RED',
        fillOpacity: 1
      },
      map: map,
       title: "9th Road"
    });
  
  const rd940ft = new  maps.Marker({
      position: { lat: 17.310496, lng: 78.780712},
    
      label: {text: "40ft", color: "red"},
      icon: {
        path:  maps.SymbolPath.CIRCLE,
        scale: 10,
        strokeWeight: 1,
        fillColor: 'WHITE',
        strokeColor: 'WHITE',
        fillOpacity: 1
      },
      map: map,
     title: "40ft wide road",
      optimized: false,
    });
  
  const rd10cs = [
    {lat: 17.311410, lng: 78.781117 },
    {lat:17.309982, lng: 78.781438 },
    {lat:17.310000,  lng: 78.781510},
    {lat:17.311426,  lng: 78.781194},
  ];
  
  const rd10 = new  maps.Polygon({
      paths: rd10cs,
      strokeColor: "BLACK",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#BLACK",
      fillOpacity: 0.5,
    });
  
  rd10.setMap(map);
  
  const rdmark10 = new  maps.Marker({
      position: { lat: 17.310058, lng:78.781447}, 
      label: {text: "10th", color: "white"},
       icon: {
        path:  maps.SymbolPath.CIRCLE,
        scale: 8,
        strokeWeight: 2,
        fillColor: 'RED',
        strokeColor: 'RED',
        fillOpacity: 1
      },
      map: map,
       title: "10th Road"
    });
  
  const rd1040ft = new  maps.Marker({
      position: { lat: 17.310678, lng: 78.781321},
      
      label: {text: "40ft", color: "red"},
      icon: {
        path:  maps.SymbolPath.CIRCLE,
        scale: 10,
        strokeWeight: 1,
        fillColor: 'WHITE',
        strokeColor: 'WHITE',
        fillOpacity: 1
      },
      map: map,
     title: "40ft wide road",
      optimized: false,
    });
  
  
  const plot118cs = [
    {lat: 17.310688, lng: 78.780728},
    {lat:17.310790, lng : 78.781259},
  ];
  
  
  const plot118 = new  maps.Polyline({
      path: plot118cs,
       geodesic: true,
      strokeColor: "#FF0000",
      strokeOpacity: 1.0,
      strokeWeight: 2,
    });
  
  plot118.setMap(map);
  
  const plot119cs = [
    {lat: 17.310386, lng:  78.780806},
    {lat:17.310479, lng : 78.781316},
  ];
  
  
  const plot119 = new  maps.Polyline({
      path: plot119cs,
       geodesic: true,
      strokeColor: "#FF0000",
      strokeOpacity: 1.0,
      strokeWeight: 2,
    });
  
  plot119.setMap(map);
  
    // const mark118 = new  maps.Marker({
    //   position: { lat: 17.310603, lng:78.780991},
  
    //   label: {text: "118", color: "white"},
    //    icon: {
    //     path:  maps.SymbolPath.CIRCLE,
    //     scale: 8,
    //     strokeWeight: 2,
    //     fillColor: 'RED',
    //     strokeColor: 'RED',
    //     fillOpacity: 1
    //   },
    //   map: map,
    //    title: "100x180: 2000 SQYDS "
    // });
  
  
    kurmiddalayout.setMap(map);
    //entrancerd.setMap(map);
    mainrd1.setMap(map);
    plot1.setMap(map);
    plot2.setMap(map);
    plot3.setMap(map);
  
  const guesthouse = [
  {lat: 17.308612, lng:78.774967},
  {lat: 17.308951, lng: 78.774798},
  {lat: 17.309033, lng:78.775098 },
  {lat: 17.308707, lng: 78.775256},
 
];
 
const commonprop = new maps.Polygon({
  paths: guesthouse,
  strokeColor: "RED",
  strokeOpacity: 0.8,
  strokeWeight: 2,
  fillOpacity: 0,
});
 
commonprop.setMap(map);
const house = new maps.Marker({
  position: { lat:17.308861, lng:78.775105 },
  label: {text: "Guest House", color: "white"},
   icon: {
    path: maps.SymbolPath.CIRCLE,
    scale: 8,
    strokeWeight: 2,
    fillColor: '',
    strokeColor: '',
    fillOpacity: 1
  },
  map: map,
   title: "Common Plot "
});


const poolc = [
  {lat: 17.309272, lng:78.775098},
  {lat: 17.309519, lng: 78.775562},
  {lat: 17.309318, lng:78.775724 },
  {lat: 17.309055, lng: 78.775224},
];
 
const pool = new  maps.Polygon({
  paths: poolc,
  strokeColor: "RED",
  strokeOpacity: 0.8,
  strokeWeight: 2,
  fillOpacity: 0,
});
 
pool.setMap(map);
 
const swimmingpool = new  maps.Marker({
  position: { lat:17.309269, lng:78.775334 },
  label: {text: "Swimming Pool", color: "white"},
   icon: {
    path:  maps.SymbolPath.CIRCLE,
    scale: 8,
    strokeWeight: 2,
    fillColor: '',
    strokeColor: '',
    fillOpacity: 1
  },
  map: map,
   title: "Swiiming Pool"
});


//
const plot1181cs = [
  {lat: 17.310511, lng: 78.780780},
  {lat: 17.310688, lng: 78.780728},
  {lat:17.310790, lng : 78.781259},
  {lat:17.310629, lng : 78.781302},
  {lat: 17.310511, lng: 78.780780},
];
 
const plot1181 = new  maps.Polyline({
  path: plot1181cs,
   geodesic: true,
  strokeColor: "BLUE",
  strokeOpacity: 1.0,
  strokeWeight: 2,
});
 
plot1181.setMap(map);
 
  const mark1180 = new  maps.Marker({
    position: { lat: 17.310667, lng:78.780905},
    label: {text: "On Sale", color: "white"},
     icon: {
      path:  maps.SymbolPath.CIRCLE,
      scale: 8,
      strokeWeight: 2,
      fillColor: '',
      strokeColor: '',
      fillOpacity: 1
    },
    map: map,
     title: "100x90: 1000 SQYDS "
  });
 
  const mark118 = new  maps.Marker({
    position: { lat: 17.310725, lng:78.781137},
    label: {text: "118/1", color: "white"},
     icon: {
      path:  maps.SymbolPath.CIRCLE,
      scale: 10,
      strokeWeight: 2,
      fillColor: 'RED',
      strokeColor: 'RED',
      fillOpacity: 1
    },
    map: map,
     title: "100x90: 1000 SQYDS "
  });
 
  const mandirc = [
    {lat: 17.310939, lng: 78.781928},
    {lat: 17.311189, lng: 78.781869},
    {lat:17.311308, lng : 78.782338},
    {lat:17.311062, lng : 78.782417},
    {lat: 17.310939, lng: 78.781928},
   
  ];
 
  const mandir = new maps.Polyline({
    path: mandirc,
     geodesic: true,
    strokeColor: "RED",
    strokeOpacity: 1.0,
    strokeWeight: 2,
  });
 
  mandir.setMap(map);
 
  const mandirplace = new maps.Marker({
    position: { lat: 17.311156, lng:78.781970},
    label: {text: "Meditation Center", color: "white"},
     icon: {
      path: maps.SymbolPath.CIRCLE,
      scale: 8,
      strokeWeight: 2,
      fillColor: '',
      strokeColor: '',
      fillOpacity: 1
    },
    map: map,
     title: "100x90: 1000 SQYDS "
  });
  };



  const themes = {
    light: {
      sidebar: {
        backgroundColor: "#ffffff",
        color: "#607489",
      },
      menu: {
        menuContent: "#fbfcfd",
        icon: "#0098e5",
        hover: {
          backgroundColor: "#c5e4ff",
          color: "#44596e",
        },
        disabled: {
          color: "#9fb6cf",
        },
      },
    },
    dark: {
      sidebar: {
        backgroundColor: "#0b2948",
        color: "#8ba1b7",
      },
      menu: {
        menuContent: "#082440",
        icon: "#59d0ff",
        hover: {
          backgroundColor: "#00458b",
          color: "#b6c8d9",
        },
        disabled: {
          color: "#3e5e7e",
        },
      },
    },
  };
  
    const [broken, setBroken] = React.useState(false);
    const [hasImage] = React.useState(false);
    const [theme] = React.useState("light");
  
    const hexToRgba = (hex, alpha) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
  
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  };


  

  


  return (

     
  
    <div style={{ height: "100vh", width: "100%",  position: "inherit", overflow:"hidden", margin: "0 auto" }}>
       <GoogleMapReact
       
        bootstrapURLKeys={{
          libraries: ["places", "geometry","visualization"],
          key: "AIzaSyB-IhLNuNEMTKd8VHG8Xyij2srPNVS_PR4",
        }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        onGoogleApiLoaded={({ map, maps }) => renderPolygon(map, maps)}
        yesIWantToUseGoogleMapApiInternals={true}
        options={{

          styles: [ { featureType: "all", elementType: "labels", stylers: [ { visibility:  "on", } ] } ],

        
       
          gestureHandling: "greedy",

          mapTypeId: "hybrid",

          mapTypeControl: false,

          streetViewControl: false,

          zoomControl: false,

          fullscreenControl: true,

          rotateControl: true,

          disableDefaultUI: false,

          panControl: true,

          scaleControl: true,

          streetViewControl: true,

          overviewMapControl: true,

          fetchPlace: true,

          featureType: "transit",

          elementType: "labels",
          

          
        }}
        ref={mapRef}
      >
      </GoogleMapReact>
    </div>
  
);

}

export default Map;